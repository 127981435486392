import React, { useState, useEffect } from 'react';
import { Home, Info, Settings, Terminal, CreditCard, MessageCircle } from 'lucide-react';
import { FaDiscord } from 'react-icons/fa';
import MinecraftLandscape from './MinecraftLandscape';

const pages = [
  { id: 'home', title: 'AETERNUM', icon: Home, color: 'bg-blue-600' },
  { id: 'info', title: 'INFORMATION', icon: Info, color: 'bg-green-900', backgroundImage: './assets/background/information.jpg' },
  { id: 'modifications', title: 'MODIFICATIONS', icon: Settings, color: 'bg-yellow-900', backgroundImage: './assets/background/modifications.jpg' },
  { id: 'commands', title: 'COMMANDS', icon: Terminal, color: 'bg-red-900', backgroundImage: './assets/background/commands.jpg' },
  { id: 'donate', title: 'DONATE', icon: CreditCard, color: 'bg-purple-900', backgroundImage: './assets/background/donate.jpg' },
];

const commandsData = [
  { command: '/discord', description: 'Provides a link to the discord server.', example: '/discord' },
  { command: '/group', description: 'Provides detailed information about your current group and it\'s members.', example: '/group' },
  { command: '/group accept', description: 'Accepts an active group invite.', example: '/group accept' },
  { command: '/group create <name>', description: 'Creates a private group with the given name. Supports spaces and alphanumeric characters.', example: '/group create Aeternum' },
  { command: '/group invite <player>', description: 'Invites a player to your group.', example: '/group invite Dinnerbone' },
  { command: '/group leave', description: 'Leaves your current group, disbanding it if you are the sole member.', example: '/group leave' },
  { command: '/group chat, /gc', description: 'Switches to group chat. All messages sent are private and only shared among members of your group.', example: '/group chat' },
  { command: '/help', description: 'Provides a link to this url.', example: '/help' },
  { command: '/ignore', description: 'Returns a list of players on your ignore list. You will not receieve messages from people on your ignore list.', example: '/ignore' },
  { command: '/ignore <player>', description: 'Adds or removes a player from your ignore list.', example: '/ignore Dinnerbone' },
  { command: '/kill, /suicide', description: 'Instantly kills your player.', example: '/kill' },
  { command: '/msg, /tell, /w <player>', description: 'Send a private message to any online player.', example: '/msg Dinnerbone Ping' },
  { command: '/netherroof', description: 'Returns information about your allowed Nether Roof access.', example: '/netherroof' },
  { command: '/reply, /r', description: 'Send a private message to the last person who sent you a private message.', example: '/reply Pong' },
  { command: '/togglechat', description: 'Toggles visibility of the default global chat.', example: '/togglechat' },
  { command: '/toggleconnectionmsgs', description: 'Toggles visibility of join and leave messages.', example: '/toggleconnectionmsgs' },
  { command: '/toggledeathmsgs', description: 'Toggles visibility of death messages.', example: '/toggledeathmsgs' },
  { command: '/tpsinfo', description: 'Show detailed TPS information.', example: '/tpsinfo' },
  { command: '/worldstats', description: 'Gives a brief overview of the world.', example: '/worldstats' },
  // ... other commands
];

const InformationPage = () => (
  <div className="text-white h-full flex items-center justify-center">
    <div className="max-w-3xl mx-auto text-center">
      <h1 className="text-4xl font-bold mb-4 text-shadow">Aeternum: Eternal Minecraft Anarchy</h1>
      <p className="mb-6 text-shadow">Founded October 10, 2024</p>
      <p className="mb-6 text-shadow">Aeternum is a unique Minecraft experience where chaos and creativity collide. With no resets and no rules, players shape a world that’s truly their own.</p>
      
      <h2 className="text-2xl font-semibold mb-2 text-shadow">What is Aeternum?</h2>
      <ul className="list-none mb-6 inline-block text-left text-shadow">
        <li>• An authentic anarchy server: No rules, no admins, no limitations.</li>
        <li>• Persistent world: Your actions leave a lasting impact on the never-resetting landscape.</li>
        <li>• Absolute freedom: Build, destroy, ally, or conquer—the choice is yours.</li>
      </ul>
      
      <h2 className="text-2xl font-semibold mb-2 text-shadow">Server Specs</h2>
      <ul className="list-none mb-6 inline-block text-left text-shadow">
        <li>• Location: Falkenstein, Germany</li>
        <li>• CPU: AMD Ryzen 9 5950X 16C/32T</li>
        <li>• Storage: 2x 3.84 TB NVMe SSD</li>
        <li>• RAM: 128 GB DDR4 ECC</li>
        <li>• Software: Folia (optimized Paper fork)</li>
      </ul>
      
      <h2 className="text-2xl font-semibold mb-2 text-shadow">What to Expect</h2>
      <ul className="list-none mb-6 inline-block text-left text-shadow">
        <li>• Complete freedom of play—no bans, no restrictions.</li>
        <li>• Permanent consequences—no rollbacks, no resets.</li>
        <li>• A challenging environment—PvP, griefing, and hacks are common.</li>
      </ul>
      
      <p className="mb-4 text-shadow">Contact: admin@aeternum.zip (technical issues only)</p>
      
      <p className="italic text-shadow">Join Aeternum — Where chaos and creativity thrive endlessly.</p>
    </div>
  </div>
);

const ModificationsPage = () => (
  <div className="text-white h-full flex items-center justify-center">
    <div className="max-w-3xl mx-auto text-center">
      <h1 className="text-4xl font-bold mb-6 text-shadow">Server Modifications</h1>
      
      <h2 className="text-2xl font-semibold mb-2 text-shadow">Core Settings</h2>
      <ul className="list-none mb-6 inline-block text-left text-shadow">
        <li>• Server Software: Folia (Paper fork with regionized multithreading)</li>
        <li>• Gamemode: Survival</li>
        <li>• Difficulty: Hard</li>
        <li>• Spawn Radius: Within 500 blocks of 0, 0</li>
      </ul>
      
      <h2 className="text-2xl font-semibold mb-2 text-shadow">Anti-Cheat Measures</h2>
      <ul className="list-none mb-6 inline-block text-left text-shadow">
        <li>• Blocks severe movement cheats (e.g., flight, boatfly)</li>
        <li>• Prevents some minor exploits (e.g., no fall damage)</li>
      </ul>
      
      <h2 className="text-2xl font-semibold mb-2 text-shadow">Duplication Glitches</h2>
      <ul className="list-none mb-6 inline-block text-left text-shadow">
        <li>• Limits vanilla and Folia-specific dupes</li>
        <li>• Custom dupe settings can be toggled based on server needs</li>
      </ul>
      
      <h2 className="text-2xl font-semibold mb-2 text-shadow">World Modifications</h2>
      <ul className="list-none mb-6 inline-block text-left text-shadow">
        <li>• Nether Void: Inaccessible</li>
        <li>• Nether Roof: Accessible, but elytra flight is prevented</li>
      </ul>
      
      <h2 className="text-2xl font-semibold mb-2 text-shadow">Custom Features</h2>
      <ul className="list-none mb-6 inline-block text-left text-shadow">
        <li>• Chat commands for feature toggling</li>
        <li>• Player ignore and private group functionality</li>
        <li>• Ender pearls persist after player death</li>
      </ul>
    </div>
  </div>
);

export default function AeternumWebsite() {
  const [activePage, setActivePage] = useState('home');
  const [previousPage, setPreviousPage] = useState(null);
  const [transitionStage, setTransitionStage] = useState('idle');
  const [searchTerm, setSearchTerm] = useState('');
  const [copyMessage, setCopyMessage] = useState('');
  const [playerCount, setPlayerCount] = useState({ now: 0, max: 0 });
  const [lastFetchTime, setLastFetchTime] = useState(0);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    fetchServerStatus();
    const intervalId = setInterval(fetchServerStatus, 60000);

    // Handle initial hash
    const hash = window.location.hash.slice(1);
    if (hash && pages.some(page => page.id === hash)) {
      handlePageChange(hash);
    }

    // Listen for hash changes
    const handleHashChange = () => {
      const newHash = window.location.hash.slice(1);
      if (newHash && pages.some(page => page.id === newHash)) {
        handlePageChange(newHash);
      }
    };
    window.addEventListener('hashchange', handleHashChange);

    return () => {
      document.body.style.overflow = 'visible';
      clearInterval(intervalId);
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  const fetchServerStatus = () => {
    const now = Date.now();
    if (now - lastFetchTime < 60000) {
      console.log('Skipping fetch due to rate limit');
      return;
    }

    fetch('https://mcapi.us/server/status?ip=aeternum.gg')
      .then(response => response.json())
      .then(data => {
        if (data.status === 'success' && data.players) {
          setPlayerCount({ now: data.players.now, max: data.players.max });
        }
        setLastFetchTime(now);
      })
      .catch(error => console.error('Failed to fetch server status:', error));
  };

  const handlePageChange = (pageId) => {
    if (pageId === activePage || transitionStage !== 'idle') return;
    
    setPreviousPage(activePage);
    setTransitionStage('sliding-out');
    setTimeout(() => {
      setActivePage(pageId);
      setTransitionStage('sliding-in');
      setTimeout(() => {
        setTransitionStage('idle');
        setPreviousPage(null);
      }, 500);
    }, 500);

    // Update the URL
    window.history.pushState(null, '', `#${pageId}`);
  };

  const handleDiscordClick = () => {
    window.open('https://discord.aeternum.gg', '_blank');
  };

  const handleDonateClick = () => {
    handlePageChange('donate');
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopyMessage(`Copied ${text} to clipboard!`);
      setTimeout(() => setCopyMessage(''), 3000);
    }).catch(err => {
      console.error('Failed to copy text: ', err);
      setCopyMessage('Failed to copy. Please try again.');
    });
  };

  const filteredCommands = commandsData.filter(cmd => 
    cmd.command.toLowerCase().includes(searchTerm.toLowerCase()) ||
    cmd.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getPageTransition = (pageId) => {
    if (pageId === 'home') {
      if (activePage === 'home') return 'translate-x-0';
      if (previousPage === 'home' && transitionStage === 'sliding-out') return 'translate-x-full';
      return '-translate-x-full';
    }
    if (pageId === activePage) {
      if (transitionStage === 'sliding-in') return 'translate-x-0';
      if (transitionStage === 'sliding-out') return 'translate-x-full';
      return 'translate-x-0';
    }
    if (pageId === previousPage && transitionStage === 'sliding-out') {
      return 'translate-x-full';
    }
    return 'translate-x-full';
  };

  return (
    <div className="h-screen w-screen overflow-hidden flex bg-gray-900">
      {/* Sidebar */}
      <div className={`w-16 flex flex-col items-center py-4 ${activePage === 'home' ? 'bg-black' : 'bg-gray-800'} transition-colors duration-500 z-50`}>
        {pages.map((page) => (
          <a
            key={page.id}
            href={`#${page.id}`}
            onClick={(e) => {
              e.preventDefault();
              handlePageChange(page.id);
            }}
            className={`p-2 mb-4 rounded-full ${activePage === page.id ? 'bg-white text-gray-800' : 'text-white hover:bg-gray-700'}`}
          >
            <page.icon size={24} />
          </a>
        ))}
        <button
          onClick={handleDiscordClick}
          className="p-2 mb-4 rounded-full text-white hover:bg-indigo-600"
          title="Join our Discord"
        >
          <FaDiscord size={24} />
        </button>
      </div>

      {/* Main content area */}
      <div className="flex-1 relative overflow-hidden">
        {/* 3D Background (only visible on home page) */}
        <div className={`absolute inset-0 z-0 transition-opacity duration-500 ${activePage === 'home' ? 'opacity-100' : 'opacity-0'}`}>
          <MinecraftLandscape />
        </div>

        {/* Pages */}
        {['home', ...pages.slice(1).map(page => page.id)].map((pageId) => (
          <div
            key={pageId}
            className={`absolute inset-0 transition-transform duration-500 ${getPageTransition(pageId)} overflow-auto z-10`}
          >
            {/* Background Image Container */}
            {pageId !== 'home' && (
              <div
                className="absolute inset-0 z-0"
                style={{
                  backgroundImage: `url(${pages.find(p => p.id === pageId).backgroundImage || ''})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  filter: 'grayscale(100%)',
                }}
              ></div>
            )}

            {/* Color Overlay */}
            {pageId !== 'home' && (
              <div className={`absolute inset-0 ${pages.find(p => p.id === pageId).color} opacity-85 z-10`}></div>
            )}

            {/* Page Content */}
            <div className="relative z-20 h-full p-8 overflow-auto">
              {pageId === 'home' && (
                <div className="flex items-center justify-center h-full">
                  <div className="text-center text-white bg-black bg-opacity-50 p-6 rounded-lg">
                    <h1 className="text-6xl font-bold mb-2 text-shadow">AETERNUM</h1>
                    <p className="text-xl mb-4 text-shadow">An eternal, unmoderated Minecraft anarchy experience.</p>
                    <p className="mb-2 text-shadow">Player Count: {playerCount.now}/{playerCount.max}</p>
                    {copyMessage && (
                      <p className="text-yellow-300 mb-2">{copyMessage}</p>
                    )}
                    <p className="mb-1 text-shadow">Server IP:</p>
                    <div className="flex justify-center space-x-4 mb-4">
                      <button 
                        onClick={() => copyToClipboard('aeternum.gg')}
                        className="px-3 py-1 bg-transparent text-white"
                      >
                        aeternum.gg
                      </button>
                      <button 
                        onClick={() => copyToClipboard('aeternum.zip')}
                        className="px-3 py-1 bg-transparent text-white"
                      >
                        aeternum.zip
                      </button>
                    </div>
                    <a
                      href="#donate"
                      onClick={(e) => {
                        e.preventDefault();
                        handlePageChange('donate');
                      }}
                      className="px-6 py-2 bg-purple-600 text-white rounded hover:bg-purple-500 transition duration-300 inline-block"
                    >
                      DONATE
                    </a>
                  </div>
                </div>
              )}
              {pageId === 'info' && <InformationPage />}
              {pageId === 'modifications' && <ModificationsPage />}
              {pageId === 'commands' && (
                <>
                  <h2 className="text-4xl font-bold text-white mb-4 text-shadow">COMMANDS</h2>
                  <input
                    type="text"
                    placeholder="Search commands..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="mb-4 p-2 w-full rounded bg-gray-700 text-white text-shadow"
                  />
                  <div className="bg-gray-800 rounded-lg overflow-hidden">
                    <table className="w-full text-sm text-left text-gray-300">
                      <thead className="text-xs uppercase bg-gray-700 text-gray-400">
                        <tr>
                          <th scope="col" className="px-6 py-3 text-shadow">Command</th>
                          <th scope="col" className="px-6 py-3 text-shadow">Description</th>
                          <th scope="col" className="px-6 py-3 text-shadow">Example</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredCommands.map((cmd, index) => (
                          <tr key={index} className="border-b bg-gray-800 border-gray-700 text-shadow">
                            <td className="px-6 py-4 font-medium text-white text-shadow">{cmd.command}</td>
                            <td className="px-6 py-4 text-shadow">{cmd.description}</td>
                            <td className="px-6 py-4 text-shadow">{cmd.example}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
              {pageId === 'donate' && (
                <div className="text-white h-full flex items-center justify-center">
                  <div className="max-w-3xl mx-auto text-center">
                    <h2 className="text-4xl font-bold mb-4 text-shadow">DONATE</h2>
                    <p className="mb-4 text-shadow">Support Aeternum's ongoing development and maintenance.</p>
                    <p className="mb-4 text-shadow">All contributions go directly towards covering current and future server costs, upgrades, and maintenance. Your support helps keep the server running at its best.</p>
                    <a 
                        href="https://donate.stripe.com/8wMaIr46Zafl58YcMM" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="font-semibold mb-2 text-shadow hover:text-blue-300 transition duration-300 inline-block"
                      >
                        - Donate via Stripe -
                    </a>
                    <p className="font-semibold mb-8 text-shadow">- Donate via PayPal -</p>
                    <p className="text-sm">(PayPal Currently Unavailable)</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}

        {/* Footer */}
        <div className="absolute bottom-0 left-0 right-0 text-center text-white text-sm py-2 bg-black bg-opacity-50 z-30">
          Background images are placeholders and will be replaced with actual server builds in the future.
        </div>
      </div>
    </div>
  );
}